<script setup>
import { onMounted } from 'vue'
import { RouterView } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { useSubmittedByStore } from '@/stores/SubmittedByStore'
import ToastNotification from '@/components/General/ToastNotification.vue'
import { getCookie } from '@/utils/cookieUtils'
import { COOKIE_NAMES } from '@/utils/constants'

// Global stores
const user = useUserStore()
const submittedBy = useSubmittedByStore()

// Fetch data on mount
onMounted(async () => {
  const routeGuardCookie = getCookie(COOKIE_NAMES.ROUTE_GUARD)

  if (routeGuardCookie === 'true') {
    try {
      // Fetch user data
      // This is the first http request that will be made when the app is loaded
      // If the user is not logged in, this request will fail with a 401 status code and the axios interceptor will redirect to the login page,
      // no further requests will be made
      await user.fetchUserData()
      // If fetchUserData is successful, proceed to fetchSubmittedByData
      submittedBy.fetchSubmittedByData()
    } catch (error) {
      // Unauthorized access or error - redirect to login
    }
  }
})
</script>

<template>
  <RouterView />
  <ToastNotification />
</template>

<style>
.tastebudz-component-header {
  font-weight: 900;
  font-family: Helvetica, sans-serif;
  text-decoration: underline;
}

/* Standard menu item style */
.tastebudz-menu-item {
  font-weight: 900;
  font-family: Helvetica, sans-serif;
  padding-top: 1em;
}

/* Remove padding for first menu item */
.tastebudz-menu-item:first-of-type {
  padding-top: 0;
}

/* Remove padding for any menu item after a horizontal rule */
hr + .tastebudz-menu-item {
  padding-top: 0;
}

.tastebudz-menu-header {
  font-weight: 900;
  font-family: Helvetica, sans-serif;
}

/* Display icons in white when buttons have a dark class applied */
.btn-dark .fa {
  color: white;
}

/* Dropdown menu active selection */
.dropdown-item.active {
  background-color: black;
}

/* Dropdown menu click selection */
.dropdown-item:active {
  background-color: black;
}
</style>
