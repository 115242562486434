<template>
  <div class="h-100 d-flex align-items-center">
    <div class="form w-100 m-auto">
      <div class="d-flex justify-content-between align-items-center">
        <h1 id="page-title">Log in</h1>
        <!-- <img src="/images/logo/logo.png" alt="TasteBudz logo" id="logo" class="logo" /> -->
        <!-- Assets in the public folder are served from the root path, hence logo.png and not public/logo.png -->
        <img src="/logo.png" alt="TasteBudz logo" id="logo" class="logo" />
      </div>
      <form @submit.prevent="login">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="username"
            v-model="username"
            placeholder="Username"
          />
          <label for="username">Username</label>
        </div>
        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="password"
            placeholder="****"
          />
          <label for="password">Password</label>
        </div>
        <div class="d-grid gap-2">
          <button
            type="submit"
            class="btn btn-primary btn-lg"
            id="login-button"
            :disabled="isWorking"
          >
            <div v-if="!isWorking">Log in</div>
            <div class="spinner-border spinner-border-sm text-light" role="status" v-if="isWorking">
              <span class="visually-hidden">Please wait...</span>
            </div>
          </button>
        </div>
      </form>
      <br />
      <div>
        <router-link to="/passcode" id="create-account-link">Create account</router-link> <br />
        <router-link to="/recover" id="recover-account-link">Recover account</router-link> <br />
        <router-link to="/status" id="system-status-link">System status</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

// Init stores
import { useUserStore } from '@/stores/UserStore'
import { useSubmittedByStore } from '@/stores/SubmittedByStore'
import { useNotificationStore } from '@/stores/NotificationStore'
const user = useUserStore()
const submittedBy = useSubmittedByStore()
const notificationStore = useNotificationStore()

const router = useRouter()
const username = ref('')
const password = ref('')

const isWorking = ref(false)

const login = async () => {
  try {
    isWorking.value = true

    const response = await axios.post('/api/login', {
      username: username.value,
      password: password.value
    })

    // If the login is successful, fetch store data and redirect to the home page
    await user.fetchUserData()
    await submittedBy.fetchSubmittedByData()

    await router.push('/')
  } catch (error) {
    // If the request fails, display an error message; http 429 means the user is being rate limited
    if (error.response.status === 429) {
      notificationStore.error('Too many requests. Wait 5 minutes before trying again.')
    } else {
      notificationStore.error(error?.response?.data?.message || error.message)
    }
  } finally {
    isWorking.value = false
  }
}

// Clear the stores when the component is mounted (e.g. on any route that renders this component)
onMounted(() => {
  submittedBy.clear()
  user.clear()
})
</script>
<style scoped>
.form {
  max-width: 330px;
  padding: 1rem;
}

.logo {
  height: 80px;
  width: auto;
}
</style>
